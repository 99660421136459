//
// IMPORTS
//

@import "reset";
@import "variables";
// Syntax highlighting @import is at the bottom of this file

/**************/
/* BASE RULES */
/**************/

@import url('https://fonts.googleapis.com/css?family=Roboto');

html {
  font-size: 100%;
  height: 100%;
}

body {
  background: #fff;
  font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #404040;
  line-height: 1.7;
  font-weight: 400;
  font-size: 18px;
  height: 100%;
}

.container {
  margin: 0 auto;
  /*max-width: 740px;*/
  max-width: 1200px;
  /* padding: 0 10px; */
  padding: 0px;
  width: 100%;
  background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $darkerGray;
  font-weight: bold;

  line-height: 1.7;
  margin: 1em 0 15px;
  padding: 0;
  /* text-align: center; */

  @include mobile {
    line-height: 1.4;
  }
}

h1 {
  font-size: 30px;
  a {
    color: inherit;
  }
}

h2 {
  font-size: 24px;
}

@media (min-width: 769px){
	.post h2::before, .post h2::after {
	    content: "";
	    display: inline-block;
	    vertical-align: middle;
	    width: 46px;
	    height: 1px;
	    background: rgb(192, 211, 218);
	    margin: 0px 30px;
	}
}

h3 {
  font-size: 20px;
  text-align: initial;
}

h4 {
  font-size: 18px;
  color: #222;
  text-align: initial;
}

p {
  margin: 15px 0;
}

table {
	padding: 0;
}

table tr {
	border-top: 1px solid #cccccc;
	background-color: white;
	margin: 0;
	padding: 0;
}

table tr:nth-child(2n) {
	background-color: #f8f8f8;
}

table tr th {
	font-weight: bold;
	border: 1px solid #cccccc;
	text-align: left;
	margin: 0;
	padding: 6px 13px;
}

table tr td {
	border: 1px solid #cccccc;
	text-align: left;
	margin: 0;
	padding: 6px 13px;
}

table tr th :first-child,
table tr td :first-child {
	margin-top: 0;
}

table tr th :last-child,
table tr td :last-child {
	margin-bottom: 0;
}

/*.detailed .entry p:first-of-type:first-letter {
    float: left;
    margin: 0.13em 0.05em 0 -0.05em;
    font-size: 3.333em;
    line-height: 0.75em;
}*/

#search-container {
  text-align: center;
}

#search-input {
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
  /* background-color: #fff3ff; */
}

.share-box {
  font-weight: bold;
  color: #222;
}

span[data-pullquote] {
  display: block;

  @include mobile {
    display: none;
  }
}

span[data-pullquote]:before {
  content: attr(data-pullquote);
  float: right;
  width: 200px;
  margin: 0 0 0.5em 30px;
  padding: 0 -5% 0 0;
  font-size: 1.3em;
  font-style: italic;
  color: green;
  text-align: center;
}

a {
  color: $blue;
  text-decoration: none;
	cursor: pointer;
  &:hover, &:active {
    color: $blue;
  }
}

ul, ol {
  margin: 15px 0;
  padding-left: 30px;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol ul, ul ol, ul ul, ol ol {
  margin: 0;
}

ul ul, ol ul {
  list-style-type: circle;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: bold;
}

img {
  max-width: 100%;
  @include mobile {
    height: auto;
  }
}

p > img {
  text-align: center;
  display: block;
  margin: 0 auto;
}

// Fixes images in popup boxes from Google Translate
.gmnoprint img {
  max-width: none;
}

.date {
  font-style: italic;
  color: $gray;
}

// Specify the color of the selection
::-moz-selection {
  color: $black;
  background: $lightGray;
}
::selection {
  color: $black;
  background: $lightGray;
}

// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/*********************/
/* LAYOUT / SECTIONS */
/*********************/

//
// .masthead
//

.wrapper-masthead {
    /* margin-bottom: 25px; */
    background-color: #fff;
    color: #000;
    max-width: 1200px;
    margin: 0 auto;
}

.masthead {
  padding: 10px 0 10px 25px;
  border-bottom: 1px solid #ECECEC;
  
  @include mobile {
    padding: 10px 0;
    text-align: center;
  }
}

.site-avatar {
  float: left;
  width: 70px;
  height: 70px;
  margin-right: 15px;

  @include mobile {
    float: none;
    display: block;
    margin: 0 auto;
  }

  img {
    border-radius: 5px;
    filter: invert(100%); 
    -webkit-filter:invert(100%); 
  }
}

.site-info {
  float: left;

  @include mobile {
    float: none;
    display: block;
    margin: 0 auto;
  }
}

.site-name {
  margin: 10px 0 0 0;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;;
  font-weight: 300;
  font-size: 28px;
  text-align: left;

  @include mobile {
    text-align: center;
  }
}

.site-name a:hover { color: inherit; text-decoration: none; }

.site-description {
  margin: -5px 0 0 0;
  color: $gray;
  font-size: 16px;

  @include mobile {
    margin: 3px 0;
  }
}

nav {
  float: right;
  margin-top: 28px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  /* text-transform: uppercase; */
  /* letter-spacing: 1px; */

  @include mobile {
    float: none;
    margin-top: 9px;
    display: block;
    font-size: 16px;
  }

  a {
    /* margin-left: 20px; */
    color: #333;
    text-align: right;
    /* font-weight: 300; */
    /* letter-spacing: 1px; */
    font-size: 15px;
    padding: 47px 10px;

    @include mobile {
        margin: 15px;
        color: #333;
        padding: 0;
        display: inline-block;
        font-size: 15px;
    }
  }
  a:hover { 
    text-decoration: none;
    color: #fff;
    background-color: #e5554e;
    
    @include mobile {
        color: #333;
        background-color: initial;
    }
  }
    
}

.active {
    text-decoration: none;
    color: #fff;
    background-color: #e5554e;
    
    @include mobile {
        color: #333;
        background-color: initial;
    }
}

//
// .main
//

.posts > .post {
  padding-bottom: 2em;
  border-bottom: 1px solid $lightGray;
  background: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  /* padding: 35px 50px; */
}

.posts > .post:last-child {
  padding-bottom: 1em;
  border-bottom: none;
}

.post {
  blockquote {
    margin: 1.8em .8em;
    /*border-left: 2px solid $gray;*/
    padding: 0.1em 1em;
    color: $gray;
    font-size: 22px;
    font-style: italic;
    background-color: #ede1ff;
  }

  .you-may-like{
    margin: 1.8em .8em;
    /*border-left: 2px solid $gray;*/
    padding: 0.1em 1em;
    color: $gray;
    font-size: 22px;
    font-style: italic;
    background-color: #ddffb5;;
  }

  .comments {
    margin-top: 10px;
  }

  .read-more {
    text-transform: uppercase;
    font-size: 15px;
  }
}

.wrapper-container {
  min-height: 100%;
  position: relative;
  background-color: #f5f5f5;
}

.wrapper-main {
  /* padding-bottom: 134px; */
}

.wrapper-footer {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  /* height: 89px; */
  /* background-color: #233140; */
  color: #000;
  font-size: 16px;
  line-height: 1;
}

footer {
  padding: 40px 0;
  text-align: center;
}

.post h1{
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
  font-weight: initial;
  margin: 0;
  font-size:36px;
  
  @include mobile {
    font-size: 25px;
  }
}

.page h1, .portfolio h1 {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  font-weight: initial;
  line-height: initial;
  margin: 10px 0;
  /* margin-bottom: 30px; */
  /* text-transform: uppercase; */
  font-size: 40px;
  
  @include mobile {
    font-size: 25px;
  }
}

hr.rocket-primary {
    border-color: #3A506B;
}

hr.rocket-light, hr.rocket-primary {
    padding: 0;
    border: none;
    border-top: solid 5px;
    text-align: center;
    max-width: 200px;
    margin: 25px auto 30px;
}

.section-title {
    margin-bottom:25px;
}

#portfolio .portfolio-item {
    margin: 0 0 15px;
    right: 0
}

#portfolio .portfolio-item .portfolio-link {
    display: block;
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    /* -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.5); */
    /* -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.5); */
    /* box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.5); */
    border: 1px solid #ccc;
}

#portfolio .portfolio-item .portfolio-link .caption {
    background: #3A506B;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all ease .5s;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s
}

#portfolio .portfolio-item .portfolio-link .caption:hover {
    opacity: 0.95;
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content {
    position: absolute;
    width: 100%;
    height: 20px;
    font-size: 20px;
    text-align: center;
    top: 33%;
    margin-top: -12px;
    color: #fff
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content i {
    margin-top: -12px
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content h3,
#portfolio .portfolio-item .portfolio-link .caption .caption-content h4 {
    margin: 0
}

#portfolio * {
    z-index: 2
}

@media (min-width:767px) {
    #portfolio .portfolio-item {
        margin: 0 0 30px
    }
}

.caption-desc {
    text-align: center;
    font-size: 20px;
    background: whitesmoke;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding: 5px 0px;
}

.caption-codebar {
    font-size: 14px;
    border-top: 2px solid #ccc;
    /* border-bottom: 1px solid #ccc; */
    /* margin-bottom: 10px; */
    padding-top: 6px;
    margin-top: 5px;
}

.sticky:before {
    content: "Featured";
    color: #FFF;
    /* background: #436aac; */
    background: #3A506B;
    padding: 10px;
    display: inline-block;
    text-align: right;
    float: right;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 10px;
}

.alignleft {
    float: left;
    text-align: left;
    margin: 0 24px 24px 0;
}

.aligncenter {
    text-align:center !important;
}

.img-centered {
    margin: 0 auto;
}

.img-responsive {
    max-width:100%;
}

.img-content {
    padding: 30px 0px 0px 0px;
}

.codebar {
    width:70%;
    margin: 0 auto;
    font-size:20px;
    text-align:center;
    margin-top:30px;
    margin-bottom:30px;
    padding: 20px 0px;
    border-top: 1px #333 solid;
    border-bottom: 1px #333 solid;
}

p.caption {
    font-size:16px;
    text-align:center;
    font-weight:bold;
    margin: 0;
    margin-bottom:30px;
}

.post-link {
    width: 100%;
    margin:0 auto;
    text-align:center;
    padding:30px;
}
    
.btn-post-link {
    font-size:18px;
    text-transform: uppercase;
    padding: 12px 20px;
}

.clear { clear:both; }

.breadcrumbs {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 15px;
  /*margin-bottom: 20px;*/
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.wp-caption-text {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.post {
    margin-bottom: 40px;
    /* padding: 50px 60px; */
    padding: 10px 40px 20px 40px;
    background: #fff;
}

.page {
    margin-bottom: 40px;
    padding: 10px 40px 20px 40px;
    /* margin-top: 40px; */
    background: #fff;
}

.portfolio {
    /* margin: 40px 0px; */
    background: #fff;
    padding: 10px 40px 20px 40px;
}

.author_title{
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
  color: #939aa1;
  /* letter-spacing: 2px; */
  margin: 0;
}

.post_date{
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
  margin-top: 0;
  color: #939aa1;
}

.bodyTagline {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 25px;
    border-top: 1px lightgray solid;
    border-bottom: 1px lightgray solid;
    margin-bottom: 25px;
}

iframe { max-width: 100%; }

/* --------------------------------

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#cd-timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #d7e4ed;
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 10px;
}
.cd-timeline-img.cd-picture {
  background: #75ce66;
}
.cd-timeline-img.cd-movie {
  background: #c03b44;
}
.cd-timeline-img.cd-location {
  background: #f0ca45;
}

.chip {
    display: inline-block;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0,0,0,.6);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: #e4e4e4;
    margin-bottom: 5px;
    margin-right: 5px;
}

.yellow.lighten-2 {
    background-color: #efd3f9 !important;
}

#gh-latest{
    font-size: 16px;
    text-align: center;		
}

.text-link {
    color: #000;
    text-decoration: none;
    border-bottom: 1px dotted #000;
}

.post-tags {
  text-align: left;
  /* line-height: 35px; */
  padding-bottom:10px;
}

.post-tags a {
  font-size: 14.5px;
  padding: 2px 13px;
  border: none;
  /* background-color: #4CAF50; */
  background-color: #3A506B;
  border-radius: 50px;
  color: #fff;
}

.pagination a, .pagination span {
  padding: 7px 18px;
  border: 1px solid #eee;
  margin-left: -2px;
  margin-right: -2px;
  background-color: #ffffff;
  display: inline-block;
}

.pagination a {    
  &:hover {
      background-color: #f1f1f1;
      color: #333;
  }
}

.pagination {
  text-align: center;
}

.author-box {
    background-color: #fff;
    font-size: 16px;
    margin-bottom: 40px;
    padding: 20px;
}

.author-box-content {
    border: 1px solid #ccc;
}

.author-box .avatar {
    margin: 3px 25px 0px 3px;
    float: left;
}

.author-box-content {
	border:1px solid #ccc;
}

.about-author {
	height:103px;
	padding-top: 5px;
	display:initial;
}

.about-author p { 
    margin: 0 0 10px; 
    line-height: initial;
    padding: 5px;
}

.author-box h4 { 
    margin: 0; 
    line-height: initial; 
    padding-top: 5px;
}

.site-footer {
    background-color: #333;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    padding: 20px 0;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
}

.footerLeft { 
    float: left; 
    padding-left:20px; 
    
    @include mobile {
        float: none;
        padding: 0;
    }

}

.footerRight { 
    float:right; 
    padding-right: 20px; 
    
    @include mobile {
        float: none;
        padding: 0;
    }

}

.entry {
    /* padding: 10px 50px 0px 10px; */
    padding: 10px;
    word-break: break-word;
    
    @include mobile {
        padding: 0;
    }

}

.featuredModule { 
	float:left;
	width:33%;
	padding:2.8%;
    
    @include mobile {
        float: none;
        width: 100%;
    }
}
.featuredModule h2 { 
	padding:0 10px;
}
.featuredModule p { width:90%; }
.frontExcerpt { 
	padding:0 10px; 
	min-height:200px; 
}

.callToAction {
	width:100%;
	background:#aaa;
	color:#fff;
	padding:60px;
	/* margin-bottom:100px; */
}

/* For the About and Portfolio pages */
.callToAction {
	margin:0px;
	display:inline-table;
}

.callToAction_Left { 
	float:left; 
	padding-top:7px;
	font-size: 25px;	
}
.callToAction_Right { float:right; }

.callToAction_button {
    background-color: #333;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    padding: 16px 24px;
    text-transform: uppercase;
    width: auto;
    display:block;
}

.callToAction_button:hover {
    background-color: #e5554e;
    color: #fff;
}

.contactLeft { 
	float:left;
	width:40%;
	padding: 0 75px 0 0;
    
    @include mobile {
        float: none;
        width: 100%;
    }
}

.contactRight {
	float: left;
    width: 55%;
    
    @include mobile {
        float: none;
        width: 100%;
    }
}

#formresult {
    margin-top: 20px;
}

.bio_fb_box { margin:0 auto; text-align:center; }

.entry-content .post_share_bar {
	margin:0px 0px 30px 0px;
}

.post_share_bar a { 
	padding:10px;
}

.avatar {
    border-radius: 50%;
}

.hello {
    text-align:center;
    margin-bottom:30px;
}

.hello p {
    font-size: 30px;
}

.hello p.hello-desc {
    font-size: 20px;
}

.search-list {
    border-bottom: 1px #ccc solid;
    margin: 10px 0;
    padding: 10px 0;
}

#isso-thread * {
    word-break: break-word;
}

.image-gallery {overflow: auto; margin-left: -1%!important;}
.image-gallery li {
    float: left; 
    display: block; 
    margin: 0 0 1% 1%; 
    width: 19%;
    
    @include mobile {
        width: 48%;
        margin: 0 0 4% 1%;
    }
}
.image-gallery li a {text-align: center; text-decoration: none!important; color: #777;}
.image-gallery li a span {display: block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 3px 0;}
.image-gallery li a img {display: block;}
.portfolio-crop {
    width: 205px; /* width of container */
    height: 205px; /* height of container */
    object-fit: cover;
    
    @include mobile {
        width: 135px; /* width of container */
        height: 135px; /* height of container */
    }
}

@media only screen and (min-width: 320px) and (max-width: 759px) {
  #carbonads {
    float: none;
    margin: 0 auto;
    max-width: 330px;
  }
  #carbonads span {
    position: relative;
  }
  #carbonads > span {
    max-width: none;
  }
  .carbon-img {
    float: left;
    margin: 0;
  }

  .carbon-img img {
    max-width: 130px !important;
  }
  .carbon-text {
    float: left;
    margin-bottom: 0;
    padding: 8px 20px;
    text-align: left;
    max-width: calc(100% - 130px - 3em);
  }
  .carbon-poweredby {
    left: 130px;
    bottom: 0;
    display: block;
    width: 100%;
  }
}

.tlemail {
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.subscribeBtn {
  width: 30%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11pt;
}

.tinyletterForm {
  border: 1px solid #ccc;
  padding: 3px;
  text-align: center;
  background: #ececec;
  margin: 8px;
  border-radius: 5px;
}

.btn-subscribe {
  line-height: 20px;
  text-decoration: none;
  background: #00000015;
  border: none;
  font-size: 12px;
  padding: 0px 7px;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  top: -1px;
  margin-left: 5px;
  color: black;
}

.btn-subscribe:hover {
  color: black;
}

.btn-subscribe > svg {
  width: 21px;
  height: 21px;
  vertical-align: bottom;
  margin: 0 -2px 0 -5px;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #e9f0f5;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #d7e4ed;
}
.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-content h2 {
  color: #303e49;
}
.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  font-size: 13px;
  font-size: 0.8125rem;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  display: inline-block;
}
.cd-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}
.cd-timeline-content .cd-read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}
.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .cd-timeline-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

